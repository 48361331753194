.profile_menu_container {
  position: absolute;
  z-index: 50;
  margin: 8px 0px;
  right: 3rem;
  max-width: 400px;
  box-shadow: 0 5px 7px #b8b7b7;
  border-radius: 10px;
  overflow: hidden;
  background-color: white;
}

.profile_menu_item {
  padding: 16px;
  font-weight: 500;
  border-top: 1px solid #ececec;
}

.profile_menu_item:hover {
  background-color: #ececec;
}
