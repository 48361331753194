.title {
  font-weight: bold;
  font-size: 1.5rem;
}
.text {
  font-family: "Reenie Beanie";
  font-size: 1rem;
}

.card {
  margin: 20px auto;
  font-family: "Lato";
  color: #fff;
  list-style: none;
}

.card .note {
  text-decoration: none;
  color: #000;
  background: #ffc;
  display: block;
  height: 10em;
  width: 10em;
  padding: 1em;
  box-shadow: 5px 5px 7px rgba(33, 33, 33, 0.7);
  transform: rotate(-6deg);
  transition: transform 0.15s linear;
}

.card .note:hover,
.card .note:focus {
  box-shadow: 10px 10px 7px rgba(0, 0, 0, 0.7);
  transform: scale(1.25);
  position: relative;
  z-index: 5;
}

.card {
  margin: 1em;
}
