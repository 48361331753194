.node {
  color: white;
  text-align: center;
  font-size: 10px;
  font-weight: 500;
  border-radius: 0.3rem;
  padding: 10px 14px;
}

.start_node {
  background-color: #ffbd5b;
}
