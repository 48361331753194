.selected_images_container {
  padding: 24px;
  height: 300px;
  overflow-y: scroll;
}

.selected_images_container::-webkit-scrollbar {
  width: 7px;
}

.selected_images_container::-webkit-scrollbar-track {
  background-color: #ececec;
  border-radius: 50px;
}

.selected_images_container::-webkit-scrollbar-thumb {
  background-color: #a9aabc;
  border-radius: 50px;
}
.selected_image {
  width: 100%;
  min-width: 400px;
  height: 100%;
  border-radius: 10px;
  object-fit: contain;
  object-position: center;
}
