.conversations_container {
  width: 300px;
}

.conversation_list_container {
  background-color: white;
  margin-top: 20px;
  height: calc(100vh - 240px);
  overflow-y: scroll;
  border-radius: 10px;
  box-shadow: 0 5px 7px #b8b7b7;
}

.conversation_list_container::-webkit-scrollbar {
  width: 7px;
}

.conversation_list_container::-webkit-scrollbar-track {
  background-color: #ececec;
  border-radius: 50px;
}

.conversation_list_container::-webkit-scrollbar-thumb {
  background-color: #a9aabc;
  border-radius: 50px;
}

.conversation_card {
  padding: 10px;
  font-size: 12px;
  font-weight: 600;
}

.conversation_card:hover {
  background-color: #ececec;
}
