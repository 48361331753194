.custom-input {
  text-align: right;
  color: #009a17;
  height: 25px !important;
  line-height: 20px !important;
}

.custom-header {
  background-color: rgb(4 76 172) !important;
}
.calender-class {
  z-index: 9999999999 !important;
  position: absolute !important;
}
