.platfroms_menu_container {
  position: absolute;
  z-index: 50;
  margin: 8px 0px;
  right: 14.5rem;
  min-width: 150px;
  box-shadow: 0 5px 7px #b8b7b7;
  border-radius: 10px;
  overflow: hidden;
  background-color: white;
}
