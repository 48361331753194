/* tab container and table section */
.tab_container .tabs_header {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  place-content: center;
  flex-direction: row;
}

.tab_container {
  border-bottom: unset;
}

.tab_container .tabs_header .tab_title {
  font-size: 16px;
  font-weight: 500;
  color: #afa9a9;
  background: transparent;
  margin-inline-end: 20px;
  padding: 18px 20px;
}

.tab_container .tabs_header .tab_title.active {
  color: hsl(224deg 60% 55%);
  position: relative;
}

.tab_container .tabs_header .tab_title.active::after {
  content: "";
  position: absolute;
  background: hsl(224deg 60% 55%);
  height: 6px;
  margin: 0px 10px;
  width: 100%;
  left: 0;
  bottom: -1px;
}

/* tab container */
/* desktop display */
@media screen and (min-width: 992px) {
  .tab_container .tabs_header {
    border-bottom: #cccccc 1px solid;
  }
  .tab_container .nav_tabs {
    margin-bottom: 0;
  }
}
