.audio_msg_progress {
  height: 15px /* 24px */;
  width: 100%;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  --range-shdw: var(--p);
  overflow: hidden;
  background-color: transparent;
  border-radius: var(--rounded-box, 1rem);
}

.audio_msg_progress::-webkit-slider-runnable-track {
  height: 0.5rem /* 8px */;
  width: 100%;
  border-radius: 15px;
  background-color: hsla(var(--bc) / 0.1);
}
.audio_msg_progress::-moz-range-track {
  height: 0.5rem /* 8px */;
  width: 100%;
  border-radius: var(--rounded-box, 1rem);
  background-color: hsla(var(--bc) / 0.1);
}
.audio_msg_progress::-webkit-slider-thumb {
  background-color: hsl(var(--b1));
  position: relative;
  height: 15px /* 24px */;
  width: 15px /* 24px */;
  border-style: none;
  border-color: #044cac;
  border-radius: 15px;
  appearance: none;
  -webkit-appearance: none;
  top: 50%;
  color: #044cac;
  transform: translateY(-50%);
  --filler-size: 100rem /* 1600px */;
  --filler-offset: 0.6rem /* 9.6px */;
  box-shadow: 0 0 0 3px hsl(var(--range-shdw)) inset, var(--focus-shadow, 0 0),
    calc(var(--filler-size) * -1 - var(--filler-offset)) 0 0 var(--filler-size);
}
.audio_msg_progress::-moz-range-thumb {
  background-color: hsl(var(--b1));
  position: relative;
  height: 15px /* 24px */;
  width: 15px /* 24px */;
  border-style: none;
  border-radius: 15px;
  top: 50%;
  color: hsl(var(--range-shdw));
  --filler-size: 100rem /* 1600px */;
  --filler-offset: 0.5rem /* 8px */;
  box-shadow: 0 0 0 3px hsl(var(--range-shdw)) inset, var(--focus-shadow, 0 0),
    calc(var(--filler-size) * -1 - var(--filler-offset)) 0 0 var(--filler-size);
}
