.panel_container {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  padding: 15px 30px;
  background-color: white;
  position: absolute;
  transition: 0.5s;
  right: -110px;
  z-index: 999999;
  box-shadow: 0 5px 7px #b8b7b7;
}

.panel_container:hover {
  right: 0;
}
