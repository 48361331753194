.chart_card_container {
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 5px 7px #b8b7b7;
  padding: 0px 20px 10px 20px;
  margin: 40px 0px 40px 0px;
}

.trend_card_container {
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 5px 7px #b8b7b7;
  padding: 0px 20px 10px 20px;
  margin: 20px 0px;
}

.chart_card_title {
  font-weight: 700;
  font-size: 22px;
  padding: 0px 20px 10px 20px;
  line-height: 1.25;
}
