.notification_container {
  position: absolute;
  z-index: 50;
  padding: 1rem;
  right: 1.25rem;
  margin-top: 0.5rem;
  height: 500px;
  width: 320px;
  overflow-y: scroll;
  box-shadow: 0 5px 7px #b8b7b7;
  border-radius: 0 0 10px 10px;
  background-color: white;
}

.notification_card {
  border-radius: 10px;
  border: 1px solid #ececec;
}

.notification_card:hover {
  background-color: #ececec;
}

.unread_notifications_counter {
  position: absolute;
  right: 0;
  background: #a10e35;
  color: white;
  top: 0;
  border-radius: 50px;
  width: 18px;
  height: 18px;
  font-size: 12px;
  text-align: center;
}

.notification_container::-webkit-scrollbar {
  width: 7px;
}

.notification_container::-webkit-scrollbar-track {
  background-color: #ececec;
  border-radius: 50px;
}

.notification_container::-webkit-scrollbar-thumb {
  background-color: #a9aabc;
  border-radius: 50px;
}

.notification_title {
  padding: 12px 10px 12px 10px;
  border-radius: 10px;
}
