.chat_container {
  display: flex;
  flex-direction: column;
  width: calc(100% - 500px);
}

.chat_messages_container {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  margin: 15px 30px 0px 30px;
  background-color: white;
  padding: 15px;
  height: calc(100vh - 315px);
  overflow-y: scroll;
  border-radius: 10px;
  box-shadow: 0 5px 7px #b8b7b7;
}

.chat_messages_container::-webkit-scrollbar {
  width: 7px;
}

.chat_messages_container::-webkit-scrollbar-track {
  background-color: #ececec;
  border-radius: 50px;
}

.chat_messages_container::-webkit-scrollbar-thumb {
  background-color: #a9aabc;
  border-radius: 50px;
}

.chat_button_message {
  text-align: center;
  color: white;
  background-color: #044cac;
  font-size: 14px;
  font-weight: 400;
  border-radius: 0.5rem;
  padding: 10px 30px;
  margin: 8px;
}

.chat_button_message:hover {
  background-color: #003089;
}

.chat_send_button {
  background-color: #044cac;
  border-radius: 9999px;
  padding: 11px;
}

.chat_send_button:hover {
  background-color: #003089;
}

.chat_send_button:disabled {
  background-color: #044cac;
  opacity: 0.7;
}
.chat_message_input {
  margin: 20px 30px 0px 30px;
  align-items: center;
}

.chat_icon_button {
  background-color: #044cac;
  border-radius: 9999px;
  margin: 8px;
  padding: 11px;
}

.chat_icon_button:hover {
  background-color: #003089;
}

.message_date {
  display: flex;
  justify-content: flex-end;
  font-size: 10px;
  font-weight: 400;
  margin-top: 5px;
}

.chat_container_header {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0px 30px;
  padding: 5px 10px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 5px 7px #b8b7b7;
}

.empty_chat_message {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #757687;
  font-size: 13px;
  margin: auto;
  padding: 15px;
  height: 28px;
  border-radius: 99999px;
  color: white;
}

.all_chats_button {
  display: block;
  width: fit-content;
  margin: 0px 30px 0px 30px;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  color: #324b4a;
}

.all_chats_button:hover {
  color: #7bb6b2;
  fill: #7bb6b2;
}
